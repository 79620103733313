<template>
  <div>
    <div class="q-pa-xl full-height">
      <div class="text-h4 text-light-blue-8 text-center">
        <div v-if="isLogin()">Halo {{ $store.state.currentUser.name }}!</div>
        Selamat datang di PPDB ONLINE SMP 2022-2023
        <div>
          <q-btn
            no-caps
            type="a"
            :href="'./data/Biaya-SMP-AFBS-2021.jpeg'"
            target="_blank"
            color="deep-orange-6"
            label="Download Biaya Masuk"
          />
        </div>
      </div>
      <!-- timeline pendaftaran  -->
      <div class="q-pa-lg">
        <q-timeline
          :layout="$q.screen.lt.sm ? 'dense' : 'loose'"
          color="blue-5"
        >
          <q-timeline-entry
            side="right"
            title="1. Buat akun PPDB"
            icon="person_add"
          >
            <div>
              Untuk mendapatkan akun PPDB SMP Al-Furqon Boarding School.
            </div>
            <div>
              Siapkan data berikut :
              <ul>
                <li>nama calon siswa</li>
                <li>alamat email aktif</li>
                <li>no HP</li>
              </ul>
            </div>
            <div class="q-pa-md">
              <q-btn
                v-show="!isLogin()"
                icon="person_add"
                outline
                color="primary"
                to="/buat-akun"
                label="Daftar "
                :disable="isLogin()"
              />
            </div>
          </q-timeline-entry>
          <q-timeline-entry side="left" title="2. Periksa e-mail" icon="email">
            <div>
              Setelah pendaftaran akun berhasil, dalam 5-10 menit periksa email
              yang anda gunakan pada langkah 1. Email berisi akun PPDB serta
              prosedur transfer biaya pendaftaran
            </div>
            <div class="text-caption text-grey-7">
              *Bila tidak ditemukan, coba lihat folder spam
            </div>
          </q-timeline-entry>
          <q-timeline-entry
            side="right"
            title="3. Transfer biaya pendaftaran"
            icon="receipt"
          >
            <div>
              Transfer biaya pendaftaran untuk dapat mengisi formulir
              pendaftaran
              <ul>
                <li>dapat dilakukan via teller, ATM, m-banking, e-banking</li>
                <li>
                  pastikan rekening dan penerima sesuai informasi di langkah 2
                </li>
                <li>pastikan nominal transfer persis (misal: Rp 100.021,-)</li>
                <li>foto bukti transfer untuk di upload (langkah 5)</li>
              </ul>
            </div>
          </q-timeline-entry>
          <q-timeline-entry side="left" title="4. Login akun PPDB" icon="login">
            <div>Login dengan username dan password dari langkah 2</div>
            <div class="q-pa-md">
              <q-btn
                v-show="!isLogin()"
                icon="login"
                outline
                color="primary"
                to="/login"
                label="Login"
                :disable="isLogin()"
              />
            </div>
          </q-timeline-entry>
          <q-timeline-entry
            side="right"
            title="5. Upload bukti transfer"
            icon="cloud_upload"
          >
            <div>Upload bukti transfer dari langkah 3</div>
            <div class="text-caption text-grey-7">
              *Verifikasi membutuhkan waktu maksimal 1x24 jam
            </div>
            <div class="q-pa-md">
              <q-btn
                v-if="!isPaid"
                icon="cloud_upload"
                outline
                @click="checkPayment"
                color="primary"
                label="Upload Bukti Transfer"
                :disable="!isLogin() || isPaid"
              />
              <q-btn
                v-else
                icon="verified"
                outline
                color="positive"
                label="Pembayaran Terverifikasi"
                :disable="true"
              />
              <div v-show="!isLogin()" class="text-caption text-red-4">
                *Login terlebih dahulu
              </div>
            </div>
          </q-timeline-entry>
          <q-timeline-entry
            side="left"
            title="6. Isi formulir PPDB"
            icon="text_snippet"
          >
            <div>
              Setelah pembayaran terverifikasi, anda dapat mengisi formulir PPDB
            </div>
            <div class="q-pa-md">
              <q-btn
                icon="text_snippet"
                outline
                @click="
                  getLatestFormData();
                  showForm = true;
                "
                color="primary"
                label="Isi Form Pendaftaran"
                :disable="!isLogin() || !isPaid"
              />
              <div v-show="!isPaid" class="text-caption text-red-4">
                *Pembayaran pendaftaran belum terverifikasi
              </div>
            </div>
          </q-timeline-entry>
          <q-timeline-entry
            side="right"
            title="7. Download PDF formulir PPDB"
            icon="picture_as_pdf"
          >
            <div>
              Download formulir PPDB dalam bentuk PDF lalu print. Tahap
              <b>pendaftaran online telah selesai</b>, tahap berikutnya adalah
              pendaftaran ulang sesuai jadwal yang telah ditentukan
            </div>
            <div class="text-caption text-grey-7">
              *Bawa hasil print pada saat daftar ulang
            </div>
            <div class="q-pa-md">
              <q-btn
                outline
                icon="picture_as_pdf"
                @click="getPDF"
                color="light-blue-8"
                label="Download PDF"
                :disable="!isLogin() || !isPaid"
              />
              <div v-show="!isPaid" class="text-caption text-red-4">
                *Pembayaran pendaftaran belum terverifikasi
              </div>
            </div>
          </q-timeline-entry>
          <q-timeline-entry
            side="left"
            title="8. Pembayaran Pertama"
            icon="payment"
            color="green-8"
          >
            <div>Melakukan Pembayaran pertama minimal Rp. 1.500.000 ,-.</div>
            <div class="q-pa-md">
              <q-btn
                v-if="!is_gol_paid"
                icon="cloud_upload"
                @click="checkGolongan"
                color="green-8"
                label="Upload Bukti Transfer"
                :disable="!isLogin() || is_gol_paid || !isPaid"
              />
              <q-btn
                v-else
                icon="verified"
                outline
                color="positive"
                label="Pembayaran Terverifikasi"
                :disable="true"
              />
              <div v-show="!isLogin()" class="text-caption text-red-4">
                *Login terlebih dahulu
              </div>
              <div v-show="!isPaid" class="text-caption text-red-4">
                *Pembayaran pendaftaran belum terverifikasi
              </div>
            </div>
          </q-timeline-entry>
          <q-timeline-entry
            side="right"
            title="9. Wawancara peserta didik & verifikasi berkas "
            icon="psychology"
            color="green-8"
          >
            <div>
              Mengikuti Observaasi dan Wawancara oleh panitia sesuai dengan
              jadwal yang sudah ditentukan. Dan melakukan proses verivikasi
              kelengkapan dan kebenaran berkas.
            </div>
          </q-timeline-entry>
          <q-timeline-entry
            side="left"
            title="10. Pembayaran Selanjutnya"
            icon="payments"
            color="green-8"
          >
            <div>
              Untuk informasi terkait Pelunasan Pembayaran, silahkan menghubungi
              Pak Jumadi: +62 857-7769-4354 (WA)
            </div>
          </q-timeline-entry>
          <q-timeline-entry
            side="right"
            title
            icon="star"
            color="green-8"
          ></q-timeline-entry>

          <q-timeline-entry heading>
            <a class="text-h6 text-green-8 text-weight-bold"
              >Selamat bergabung di SMP Al-Furqon Boarding School</a
            >
          </q-timeline-entry>
        </q-timeline>
      </div>
    </div>
    <!-- end of timeline pendaftaran  -->

    <!-- form pendaftaran  -->
    <q-dialog
      v-model="showForm"
      maximized
      persistent
      transition-show="slide-left"
      transition-hide="slide-down"
    >
      <q-card class="bg-grey-3">
        <div style="width: 100%">
          <q-linear-progress
            size="24px"
            :value="progressIsiFormulir * step"
            color="warning"
          >
            <div class="absolute-full flex flex-start">
              <q-badge
                color="warning"
                text-color="white"
                :label="progressLabel1"
              />
            </div>
          </q-linear-progress>
        </div>
        <div v-show="step == 1" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            <div style="max-width: 800px; margin: 0 auto; padding-left: 30px">
              Data Siswa
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Data Pribadi
              </div>
              <div class="pertanyaan">
                <label for="nama_anak">
                  Nama Lengkap
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  id="nama_anak"
                  ref="nama_anak"
                  v-model="newData.nama"
                />
              </div>
              <div class="pertanyaan">
                <label for="alias">
                  Nama Panggilan
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="alias"
                  v-model="newData.alias"
                />
              </div>
              <div class="pertanyaan">
                <label for="noid">
                  Kewarganegaraan
                  <span class="text-red">*</span>
                </label>
                <q-btn-group unelevated spread class="q-mb-md">
                  <q-btn
                    no-caps
                    @click="newData.nation = 'WNI'"
                    :outline="newData.nation == 'WNI' ? false : true"
                    color="light-blue-5"
                    label="WNI"
                  />
                  <q-btn
                    no-caps
                    @click="newData.nation = 'WNA'"
                    :outline="newData.nation == 'WNA' ? false : true"
                    color="light-blue-5"
                    label="WNA"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="jenis_kelamin">
                  Jenis Kelamin
                  <span class="text-red">*</span>
                </label>
                <q-btn-group unelevated spread class="q-mb-md">
                  <q-btn
                    no-caps
                    @click="newData.jenis_kelamin = 'L'"
                    :outline="newData.jenis_kelamin == 'L' ? false : true"
                    color="light-blue-5"
                    label="Laki-laki"
                  />
                  <q-btn
                    no-caps
                    @click="newData.jenis_kelamin = 'P'"
                    :outline="newData.jenis_kelamin == 'P' ? false : true"
                    color="light-blue-5"
                    label="Perempuan"
                  />
                </q-btn-group>
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Data Tambahan
              </div>
              <div class="pertanyaan">
                <label for="temp_lahir">
                  Tempat Lahir
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="temp_lahir"
                  v-model="newData.temp_lahir"
                />
              </div>
              <div class="pertanyaan">
                <label for="tgl_lahir">
                  Tanggal Lahir (DD/MM/YYYY)
                  <span class="text-red">*</span>
                </label>

                <q-input
                  v-bind="inputAtr"
                  ref="tgl_lahir"
                  v-model="newData.tgl_lahir"
                  mask="##/##/####"
                  fill-mask
                />
              </div>
              <div class="pertanyaan">
                <label for="anak_ke">
                  Anak Ke
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  type="number"
                  ref="anak_ke"
                  v-model="newData.anak_ke"
                />
              </div>
              <div class="pertanyaan">
                <label for="saudara">
                  Jumlah Saudara Kandung
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  type="number"
                  ref="saudara"
                  v-model="newData.saudara_kandung"
                />
              </div>
              <div class="pertanyaan">
                <label for="saudara">
                  Jumlah Saudara Tiri
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  type="number"
                  ref="saudara_tiri"
                  v-model="newData.saudara_tiri"
                />
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Alamat
              </div>
              <div class="pertanyaan">
                <label for="add">
                  Nama Jalan
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="alamat"
                  v-model="newData.alamat"
                />
              </div>
              <div class="pertanyaan">
                <label for="rt_rw">
                  RT / RW
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="rt_rw"
                  v-model="newData.rt_rw"
                  mask="##/##"
                  fill-mask
                />
              </div>
              <div class="pertanyaan">
                <label for="kel">
                  Desa / Kelurahan
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="kel"
                  v-model="newData.kelurahan"
                />
              </div>
              <div class="pertanyaan">
                <label for="kec">
                  Kecamatan
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="kec"
                  v-model="newData.kecamatan"
                />
              </div>
              <div class="pertanyaan">
                <label for="kab_kota">
                  Kabupaten / Kota
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="kab_kota"
                  v-model="newData.kab_kota"
                />
              </div>
              <div class="pertanyaan">
                <label for="provinsi">
                  Provinsi
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="provinsi"
                  v-model="newData.provinsi"
                />
              </div>
              <div class="pertanyaan">
                <label for="pos">
                  Kode POS
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="pos"
                  v-model="newData.kode_pos"
                />
              </div>
            </q-card>
          </div>
          <div class="col-2 bottom row items-end">
            <q-btn
              outline
              class="q-px-sm bg-white"
              label="Back"
              color="light-blue-8"
              @click="showForm = false"
            />
            <q-btn
              unelevated
              class="q-px-sm"
              label="Next"
              color="light-blue-8"
              @click="nextStep(2)"
            />
          </div>
        </div>
        <div v-show="step == 2" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            <div style="max-width: 800px; margin: 0 auto; padding-left: 30px">
              Data Orang Tua
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Identitas Ayah
              </div>
              <div class="pertanyaan">
                <label for="nama_ayah">
                  Nama Lengkap
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  id="nama_ayah"
                  ref="nama_ayah"
                  v-model="newData.nama_ayah"
                />
              </div>
              <div class="pertanyaan">
                <label for="noid">
                  Kewarganegaraan
                  <span class="text-red">*</span>
                </label>
                <q-btn-group unelevated spread class="q-mb-md">
                  <q-btn
                    no-caps
                    @click="newData.nation_ayah = 'WNI'"
                    :outline="newData.nation_ayah == 'WNI' ? false : true"
                    color="light-blue-5"
                    label="WNI"
                  />
                  <q-btn
                    no-caps
                    @click="newData.nation_ayah = 'WNA'"
                    :outline="newData.nation_ayah == 'WNA' ? false : true"
                    color="light-blue-5"
                    label="WNA"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="temp_lahir_ayah">
                  Tempat Lahir
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="temp_lahir_ayah"
                  v-model="newData.temp_lahir_ayah"
                />
              </div>
              <div class="pertanyaan">
                <label for="tgl_lahir_ayah">
                  Tanggal Lahir
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="tgl_lahir_ayah"
                  v-model="newData.tgl_lahir_ayah"
                  mask="##/##/####"
                  fill-mask
                />
              </div>
              <div class="pertanyaan">
                <label for="pend_ayah">
                  Pendidikan Terakhir
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="pend_ayah"
                  v-model="newData.pend_ayah"
                  placeholder
                />
              </div>
              <div class="pertanyaan">
                <label for="pekr_ayah">
                  Pekerjaan
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="pekr_ayah"
                  v-model="newData.kerja_ayah"
                />
              </div>
              <div class="pertanyaan">
                <label for="telp_ayah">
                  No Telp. / HP
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="telp_ayah"
                  v-model="newData.no_hp_ayah"
                />
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Identitas Ibu
              </div>
              <div class="pertanyaan">
                <label for="nama_ibu">
                  Nama Lengkap
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="nama_ibu"
                  v-model="newData.nama_ibu"
                />
              </div>
              <div class="pertanyaan">
                <label for="noid">
                  Kewarganegaraan
                  <span class="text-red">*</span>
                </label>
                <q-btn-group unelevated spread class="q-mb-md">
                  <q-btn
                    no-caps
                    @click="newData.nation_ibu = 'WNI'"
                    :outline="newData.nation_ibu == 'WNI' ? false : true"
                    color="light-blue-5"
                    label="WNI"
                  />
                  <q-btn
                    no-caps
                    @click="newData.nation_ibu = 'WNA'"
                    :outline="newData.nation_ibu == 'WNA' ? false : true"
                    color="light-blue-5"
                    label="WNA"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="temp_lahir_ibu">
                  Tempat Lahir
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="temp_lahir_ibu"
                  v-model="newData.temp_lahir_ibu"
                />
              </div>
              <div class="pertanyaan">
                <label for="tgl_lahir_ibu">
                  Tanggal Lahir
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="tgl_lahir_ibu"
                  v-model="newData.tgl_lahir_ibu"
                  mask="##/##/####"
                  fill-mask
                />
              </div>
              <div class="pertanyaan">
                <label for="pend_ibu">
                  Pendidikan Terakhir
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="pend_ibu"
                  v-model="newData.pend_ibu"
                  placeholder
                />
              </div>
              <div class="pertanyaan">
                <label for="kerja_ibu">
                  Pekerjaan
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="kerja_ibu"
                  v-model="newData.kerja_ibu"
                />
              </div>
              <div class="pertanyaan">
                <label for="no_hp_ibu">
                  No Telp. / HP
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="no_hp_ibu"
                  v-model="newData.no_hp_ibu"
                />
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Data Lain
              </div>
              <div class="pertanyaan">
                <label for="email_ortu">
                  Email Aktif
                  <span class="text-red">*</span>
                </label>
                <q-input
                  disable
                  v-bind="inputAtr"
                  ref="email_ortu"
                  v-model="newData.email_ortu"
                  placeholder="xxxxx@gmail.com"
                />
              </div>
              <div class="pertanyaan">
                <label for="pendapatan">
                  Pendapatan Perbulan
                  <span class="text-red">*</span>
                </label>
                <div>
                  <q-radio
                    keep-color
                    v-model="newData.pendapatan"
                    val="Di bawah Rp 3.000.000"
                    label="Di bawah Rp 3.000.000"
                    color="light-blue-5"
                  />
                  <q-radio
                    keep-color
                    v-model="newData.pendapatan"
                    val="Rp 3.000.000 - Rp 6.000.000"
                    label="Rp 3.000.000 - Rp 6.000.000"
                    color="light-blue-5"
                  />
                  <q-radio
                    keep-color
                    v-model="newData.pendapatan"
                    val="Di atas Rp 6.000.000"
                    label="Di atas Rp 6.000.000"
                    color="light-blue-5"
                  />
                </div>
              </div>
              <div class="pertanyaan">
                <label for="ktp">
                  KTP Orang tua
                  <span class="text-red">*</span>
                </label>
                <div>
                  <q-field v-bind="inputAtr" ref="file_ktp" for="ktp">
                    <template v-slot:append>
                      <q-icon name="attach_file" color="light-blue-8" />
                    </template>
                    <template v-slot:control>{{ newData.file_ktp }}</template>
                  </q-field>
                  <input
                    type="file"
                    id="ktp"
                    ref="ktp"
                    accept=".png, .jpg, .jpeg"
                    @change="chgFileKTP"
                    style="display: none"
                  />
                </div>
              </div>
              <div class="pertanyaan">
                <label for="kk">
                  Kartu Keluarga
                  <span class="text-red">*</span>
                </label>
                <div>
                  <q-field v-bind="inputAtr" ref="file_kk" for="kk">
                    <template v-slot:append>
                      <q-icon name="attach_file" color="light-blue-8" />
                    </template>
                    <template v-slot:control>{{ newData.file_kk }}</template>
                  </q-field>
                  <input
                    type="file"
                    id="kk"
                    ref="kk"
                    accept=".png, .jpg, .jpeg"
                    @change="chgFileKK"
                    style="display: none"
                  />
                </div>
              </div>
              <div class="pertanyaan">
                <label for="akte">
                  Akte Kelahiran
                  <span class="text-red">*</span>
                </label>
                <div>
                  <q-field v-bind="inputAtr" ref="file_akte" for="akte">
                    <template v-slot:append>
                      <q-icon name="attach_file" color="light-blue-8" />
                    </template>
                    <template v-slot:control>{{ newData.file_akte }}</template>
                  </q-field>
                  <input
                    type="file"
                    id="akte"
                    ref="akte"
                    accept=".png, .jpg, .jpeg"
                    @change="chgFileAkte"
                    style="display: none"
                  />
                </div>
              </div>
            </q-card>
          </div>
          <div class="col-2 bottom row items-end">
            <q-btn
              outline
              class="q-px-sm no-border-radius bg-white"
              label="Back"
              color="light-blue-8"
              @click="step = 1"
            />
            <q-btn
              unelevated
              class="q-px-sm no-border-radius"
              label="Next"
              color="light-blue-8"
              @click="nextStep(3)"
            />
          </div>
        </div>
        <div v-show="step == 3" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            <div style="max-width: 800px; margin: 0 auto; padding-left: 30px">
              Data Wali Siswa
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Identitas Wali
              </div>
              <div class="pertanyaan">
                <label for="nama_wali">Nama Lengkap</label>
                <q-input
                  dense
                  outlined
                  ref="nama_wali"
                  v-model="newData.nama_wali"
                />
              </div>
              <div class="pertanyaan">
                <label for="nation_wali">Kewarganegaraan</label>
                <q-btn-group unelevated spread class="q-mb-md">
                  <q-btn
                    no-caps
                    @click="newData.nation_wali = 'WNI'"
                    :outline="newData.nation_wali == 'WNI' ? false : true"
                    color="light-blue-5"
                    label="WNI"
                  />
                  <q-btn
                    no-caps
                    @click="newData.nation_wali = 'WNA'"
                    :outline="newData.nation_wali == 'WNA' ? false : true"
                    color="light-blue-5"
                    label="WNA"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="temp_lahir_wali">Tempat Lahir</label>
                <q-input
                  dense
                  outlined
                  ref="temp_lahir_wali"
                  v-model="newData.temp_lahir_wali"
                />
              </div>
              <div class="pertanyaan">
                <label for="tgl_lahir_wali">Tanggal Lahir</label>
                <q-input
                  dense
                  outlined
                  ref="rt_rw"
                  v-model="newData.tgl_lahir_wali"
                  mask="##/##/####"
                  fill-mask
                />
              </div>
              <div class="pertanyaan">
                <label for="pend_wali">Pendidikan Terakhir</label>
                <q-input
                  dense
                  outlined
                  ref="pend_wali"
                  v-model="newData.pend_wali"
                  placeholder
                />
              </div>
              <div class="pertanyaan">
                <label for="kerja_wali">Pekerjaan</label>
                <q-input
                  dense
                  outlined
                  ref="kerja_wali"
                  v-model="newData.kerja_wali"
                />
              </div>
              <div class="pertanyaan">
                <label for="no_hp_wali">No Telp. / HP</label>
                <q-input
                  dense
                  outlined
                  ref="no_hp_wali"
                  v-model="newData.no_hp_wali"
                />
              </div>
              <div class="pertanyaan">
                <label for="alamat_wali">Alamat Lengkap</label>
                <q-input
                  autogrow
                  dense
                  outlined
                  ref="alamat_wali"
                  v-model="newData.alamat_wali"
                  placeholder
                />
              </div>
              <div class="pertanyaan">
                <label for="pos_wali">Kode POS</label>
                <q-input
                  dense
                  outlined
                  ref="pos_wali"
                  v-model="newData.pos_wali"
                />
              </div>
            </q-card>
          </div>
          <div class="col-2 bottom row items-end">
            <q-btn
              outline
              class="q-px-sm no-border-radius bg-white"
              label="Back"
              color="light-blue-8"
              @click="step = 2"
            />
            <q-btn
              unelevated
              class="q-px-sm no-border-radius"
              label="Next"
              color="light-blue-8"
              @click="nextStep(4)"
            />
          </div>
        </div>
        <div v-show="step == 4" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            <div style="max-width: 800px; margin: 0 auto; padding-left: 30px">
              Data Periodik Siswa
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Data Periodik
              </div>
              <div class="pertanyaan">
                <label for="gol_darah">
                  Golongan Darah
                  <span class="text-red">*</span>
                </label>
                <q-btn-group unelevated spread class="q-mb-md">
                  <q-btn
                    no-caps
                    @click="newData.gol_darah = 'A'"
                    :outline="newData.gol_darah == 'A' ? false : true"
                    color="light-blue-5"
                    label="A"
                  />
                  <q-btn
                    no-caps
                    @click="newData.gol_darah = 'B'"
                    :outline="newData.gol_darah == 'B' ? false : true"
                    color="light-blue-5"
                    label="B"
                  />
                  <q-btn
                    no-caps
                    @click="newData.gol_darah = 'O'"
                    :outline="newData.gol_darah == 'O' ? false : true"
                    color="light-blue-5"
                    label="O"
                  />
                  <q-btn
                    no-caps
                    @click="newData.gol_darah = 'AB'"
                    :outline="newData.gol_darah == 'AB' ? false : true"
                    color="light-blue-5"
                    label="AB"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="tinggi_badan">
                  Tinggi Badan (cm)
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  type="number"
                  ref="tinggi_badan"
                  v-model="newData.tinggi_badan"
                />
              </div>
              <div class="pertanyaan">
                <label for="berat_badan">
                  Berat Badan (Kg)
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  type="number"
                  ref="berat_badan"
                  v-model="newData.berat_badan"
                />
              </div>
              <div class="pertanyaan">
                <label for="riwayat_penyakit"
                  >Penyakit yang pernah diderita</label
                >
                <q-input
                  v-bind="inputAtr"
                  ref="riwayat_penyakit"
                  v-model="newData.riwayat_penyakit"
                  placeholder
                />
              </div>
              <div class="pertanyaan">
                <label for="kelainan">Kelainan jasmani / lainnya</label>
                <q-input
                  v-bind="inputAtr"
                  ref="kelainan"
                  v-model="newData.kelainan"
                  placeholder
                />
              </div>
            </q-card>
            <div class="col-2 bottom row items-end">
              <q-btn
                outline
                class="q-px-sm no-border-radius bg-white"
                label="Back"
                color="light-blue-8"
                @click="step = 3"
              />
              <q-btn
                unelevated
                class="q-px-sm no-border-radius"
                label="Next"
                color="light-blue-8"
                @click="nextStep(5)"
              />
            </div>
          </div>
        </div>
        <div v-show="step == 5" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            <div style="max-width: 800px; margin: 0 auto; padding-left: 30px">
              Data Pendidikan Sebelumnya
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Pendidikan
              </div>
              <div class="pertanyaan">
                <label for="asal_sekolah">
                  Nama Sekolah Asal
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  autogrow
                  ref="asal_sekolah"
                  v-model="newData.asal_sekolah"
                />
              </div>
              <div class="pertanyaan">
                <label for="alm_sekolah_asal">
                  Alamat Sekolah
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  autogrow
                  ref="alm_sekolah_asal"
                  v-model="newData.alamat_sekolah_asal"
                />
              </div>
              <div class="pertanyaan">
                <label for="nisn">
                  NISN
                  <span class="text-red">*</span>
                </label>
                <q-input
                  v-bind="inputAtr"
                  ref="nisn"
                  v-model="newData.nisn"
                  placeholder="9900087XXX"
                />
              </div>
              <div class="pertanyaan">
                <label for="no_ijazah">No. Ijazah / STTB</label>
                <q-input
                  v-bind="inputAtr"
                  ref="no_ijazah"
                  v-model="newData.no_ijazah"
                  placeholder="DN-03 Dd 109XXX"
                />
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver"
              >
                Prestasi (maksimal 5)
              </div>
              <q-card-section>
                <q-card
                  v-for="(x, i) in listPrestasi"
                  :key="i"
                  class="full-width q-my-md bg-grey-2"
                  style="overflow: hidden"
                >
                  <q-card-section class="row justify-end q-pa-none bg-grey-5">
                    <q-btn
                      unleveted
                      @click="removePrestasi(x.id, i)"
                      icon="delete"
                      size="sm"
                      color="negative"
                      class="no-border-radius"
                    />
                  </q-card-section>
                  <q-card-section class="row justify-between">
                    <div class="col-8 text-bold">{{ x.nama_prestasi }}</div>
                    <div class="col-4 text-grey-9 text-caption text-right">
                      {{ x.bidang }}
                    </div>
                  </q-card-section>
                  <q-card-section class="row justify-between">
                    <div class="col-8 text-grey-9 text-caption">
                      {{ x.instansi }}
                    </div>
                    <div class="col-4 text-grey-9 text-caption text-right">
                      {{ x.tahun }}
                    </div>
                  </q-card-section>
                </q-card>
                <div class="row full-width justify-center">
                  <q-btn
                    flat
                    outlined
                    @click="newPrestasi = true"
                    color="light-blue-6"
                    style="width: 100px"
                    label="+ Prestasi"
                    no-caps
                    :disable="listPrestasi.length >= 5"
                  />
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-2 bottom row items-end">
            <q-btn
              outline
              class="q-px-sm no-border-radius bg-white"
              label="Back"
              color="light-blue-8"
              @click="step = 4"
            />
            <q-btn
              unelevated
              class="q-px-sm no-border-radius"
              label="Submit"
              color="light-blue-8"
              @click="nextStep(6)"
            />
          </div>
        </div>
      </q-card>
      <q-dialog v-model="newPrestasi" persistent>
        <q-card>
          <q-card-section class="bg-light-blue-8 text-white text-center"
            >Tambah Data Prestasi</q-card-section
          >
          <q-card-section style>
            <label class="text-grey">Nama Prestasi</label>
            <q-input
              dense
              outlined
              v-model="prestasi.nama_prestasi"
              class="full-width"
            />
            <label class="text-grey">Bidang</label>
            <q-input
              dense
              outlined
              v-model="prestasi.bidang"
              class="full-width"
            />
            <label class="text-grey">Penyelenggara</label>
            <q-input
              dense
              outlined
              v-model="prestasi.instansi"
              class="full-width"
            />
            <label class="text-grey">Tahun</label>
            <q-input
              dense
              outlined
              v-model="prestasi.tahun"
              class="full-width"
            />

            <div class="row justify-evenly q-pt-lg">
              <q-btn
                outline
                color="light-blue-8"
                class="q-mx-sm"
                @click="
                  newPrestasi = false;
                  prestasi = {};
                "
                label="Batal"
                style="width: 100px"
                no-caps
              />
              <q-btn
                unelevated
                color="light-blue-8"
                class="q-mx-sm"
                @click="addPrestasi"
                label="Simpan"
                style="width: 100px"
                no-caps
              />
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </q-dialog>
    <!-- end of form pendaftaran  -->

    <!-- dialog belum isi formulir  -->
    <q-dialog v-model="isBelumIsiFormulir">
      <q-card class="q-pa-md">
        <q-card-section>Anda belum isi formulir pendaftaran!</q-card-section>
        <q-card-sectio class="row justify-center">
          <q-btn
            v-close-popup
            flat
            color="light-blue-6"
            @click="showForm = true"
            label="isi formulir"
          />
        </q-card-sectio>
      </q-card>
    </q-dialog>
    <!-- end of dialog belum isi formulir  -->

    <!-- dialog sudah upload bukti transfer  -->
    <q-dialog v-model="isSudahUploadBukti">
      <q-card class="q-pa-md">
        <q-card-section
          >Anda sudah upload bukti transfer, Apakah anda akan upload
          ulang?</q-card-section
        >
        <q-card-sectio class="row justify-between">
          <q-btn no-caps v-close-popup outline color="primary" label="Tidak" />
          <q-btn
            no-caps
            v-close-popup
            color="primary"
            @click="$router.push('/pendaftaran/konfirmasi')"
            label="Ya"
          />
        </q-card-sectio>
      </q-card>
    </q-dialog>
    <!-- end of dialog sudah upload bukti transfer  -->

    <q-page-container class="bg-white full-width">
      <router-view />
    </q-page-container>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      is_gol_paid: false,
      inputAtr: {
        dense: true,
        outlined: true,
        lazyRules: "ondemand",
        rules: [this.cekIsi],
      },
      isPaid: false,
      sudahIsiForm: false,
      progressIsiFormulir: 0.2,
      step: 1,
      showForm: false,

      newData: {
        nation: "WNI",
        nation_ayah: "WNI",
        nation_ibu: "WNI",
        nation_wali: "WNI",
        jenis_kelamin: "L",
        gol_darah: "O",
      },
      listPrestasi: [],
      prestasi: {},
      newPrestasi: false,
      isBelumIsiFormulir: false,
      isSudahUploadBukti: false,
      selectKTP: "",
      selectKK: "",
      selectAkte: "",
      FileKTP: null,
      FileKK: null,
      FileAkte: null,
    };
  },
  mounted() {
    if (!this.isLogin()) return;

    this.getLatestFormData();
    this.cekPembayaran();
    this.cekPayment();
  },
  methods: {
    nextStep(step) {
      if (step == 2) {
        this.$refs.nama_anak.validate();
        this.$refs.alias.validate();
        this.$refs.temp_lahir.validate();
        this.$refs.tgl_lahir.validate();
        this.$refs.anak_ke.validate();
        this.$refs.saudara.validate();
        this.$refs.saudara_tiri.validate();
        this.$refs.alamat.validate();
        this.$refs.rt_rw.validate();
        this.$refs.kel.validate();
        this.$refs.kec.validate();
        this.$refs.kab_kota.validate();
        this.$refs.provinsi.validate();
        this.$refs.pos.validate();

        if (
          this.$refs.nama_anak.hasError ||
          this.$refs.alias.hasError ||
          this.$refs.temp_lahir.hasError ||
          this.$refs.tgl_lahir.hasError ||
          this.$refs.anak_ke.hasError ||
          this.$refs.saudara.hasError ||
          this.$refs.saudara_tiri.hasError ||
          this.$refs.alamat.hasError ||
          this.$refs.rt_rw.hasError ||
          this.$refs.kel.hasError ||
          this.$refs.kec.hasError ||
          this.$refs.kab_kota.hasError ||
          this.$refs.provinsi.hasError ||
          this.$refs.pos.hasError
        ) {
        } else {
          this.step = 2;
        }
      }

      if (step == 3) {
        this.$refs.nama_ayah.validate();
        this.$refs.temp_lahir_ayah.validate();
        this.$refs.tgl_lahir_ayah.validate();
        this.$refs.pend_ayah.validate();
        this.$refs.pekr_ayah.validate();
        this.$refs.telp_ayah.validate();

        this.$refs.nama_ibu.validate();
        this.$refs.temp_lahir_ibu.validate();
        this.$refs.tgl_lahir_ibu.validate();
        this.$refs.pend_ibu.validate();
        this.$refs.kerja_ibu.validate();
        this.$refs.no_hp_ibu.validate();

        this.$refs.email_ortu.validate();

        this.$refs.file_ktp.resetValidation();
        this.$refs.file_kk.resetValidation();
        this.$refs.file_akte.resetValidation();
        if (this.newData.file_ktp.length == 0) this.$refs.file_ktp.validate();
        if (this.newData.file_kk.length == 0) this.$refs.file_kk.validate();
        if (this.newData.file_akte.length == 0) this.$refs.file_akte.validate();

        if (
          this.$refs.nama_ayah.hasError ||
          this.$refs.temp_lahir_ayah.hasError ||
          this.$refs.tgl_lahir_ayah.hasError ||
          this.$refs.pend_ayah.hasError ||
          this.$refs.pekr_ayah.hasError ||
          this.$refs.telp_ayah.hasError ||
          this.$refs.nama_ibu.hasError ||
          this.$refs.temp_lahir_ibu.hasError ||
          this.$refs.tgl_lahir_ibu.hasError ||
          this.$refs.pend_ibu.hasError ||
          this.$refs.kerja_ibu.hasError ||
          this.$refs.no_hp_ibu.hasError ||
          this.$refs.email_ortu.hasError ||
          this.$refs.file_ktp.hasError ||
          this.$refs.file_kk.hasError ||
          this.$refs.file_akte.hasError
        ) {
        } else {
          this.step = 3;
        }
      }
      if (step == 4) {
        // data wali
        this.step = 4;
      }

      if (step == 5) {
        this.$refs.tinggi_badan.validate();
        this.$refs.berat_badan.validate();

        if (
          this.$refs.tinggi_badan.hasError ||
          this.$refs.berat_badan.hasError
        ) {
        } else {
          this.step = 5;
        }
      }
      if (step == 6) {
        this.$refs.asal_sekolah.validate();
        this.$refs.alm_sekolah_asal.validate();
        this.$refs.nisn.validate();

        if (
          this.$refs.asal_sekolah.hasError ||
          this.$refs.alm_sekolah_asal.hasError ||
          this.$refs.nisn.hasError
        ) {
          console.log(123);
        } else {
          this.onSubmit();
        }
      }
    },
    cekIsi(val) {
      console.log(val);
      return !!val || "Wajib diisi";
    },
    debug(x) {
      console.log(x);
    },
    removePrestasi(id, idx) {
      this.$http.get("/remove_prestasi/" + id, {}).then(async (result) => {});
      this.listPrestasi.splice(idx, 1);
    },
    getLatestFormData() {
      this.$http
        .get(
          "/pendaftar_byusername/" + this.$store.state.currentUser.username,
          {}
        )
        .then(async (result) => {
          this.newData = result.data;
          this.newData.username = this.$store.state.currentUser.username;
          this.newData.email_ortu = this.$store.state.currentUser.username;
          this.newData.tgl_lahir = this.newData.tgl_lahir
            .split("-")
            .reverse()
            .join("/");
          this.newData.tgl_lahir_ayah = this.newData.tgl_lahir_ayah
            .split("-")
            .reverse()
            .join("/");
          this.newData.tgl_lahir_ibu = this.newData.tgl_lahir_ibu
            .split("-")
            .reverse()
            .join("/");
          if (this.newData.tgl_lahir_wali) {
            this.newData.tgl_lahir_wali = this.newData.tgl_lahir_wali
              .split("-")
              .reverse()
              .join("/");
          }
        });
      this.$http
        .get("/prestasi_pendaftar/" + this.$store.state.currentUser.id, {})
        .then(async (res) => {
          this.listPrestasi = res.data;
        });
    },
    isLogin() {
      if (this.$store.state.currentUser === null) return false;
      return this.$store.state.currentUser ? true : false;
    },
    checkPayment() {
      this.$http
        .get(
          "/get_status_pendaftaran/" + this.$store.state.currentUser.username,
          {}
        )
        .then((result) => {
          if (!result.data.file_transfer)
            this.$router.push("/pendaftaran/konfirmasi");
          else if (result.data.status == "N") this.isSudahUploadBukti = true;
        })
        .catch((err) => {});
    },
    checkGolongan() {
      this.$router.push("/pendaftaran/konfirmasigolongan");
    },

    //old
    uploadForm() {
      this.$http
        .get(
          "/get_status_pendaftaran/" + this.$store.state.currentUser.username,
          {}
        )
        .then((result) => {
          if (!result.data.file_transfer)
            this.$router.push("/pendaftaran/konfirmasi");
          else if (result.data.status == "N") this.isSudahUploadBukti = true;
          else if (result.data.status == "Y") {
            let routeData = this.$router.resolve({
              name: "form-final",
              params: { nisn: result.data.nisn },
            });
            window.open(routeData.href, "_blank");
          }
        })
        .catch((err) => {
          this.isBelumIsiFormulir = true;
        })
        .catch((err) => {
          this.isBelumIsiFormulir = true;
        });
    },
    async cekPembayaran() {
      await this.$http
        .get(
          "/get_status_pembayaran/" + this.$store.state.currentUser.username,
          {}
        )
        .then((result) => {
          if (result.data.status == "Y") {
            this.sudahIsiForm = true;
            this.isPaid = true;
          } else {
            this.sudahIsiForm = false;
          }
        })
        .catch((err) => {
          this.sudahIsiForm = false;
        });
    },
    async cekPayment() {
      await this.$http
        .get(
          "/get_status_payment/" + this.$store.state.currentUser.username,
          {}
        )
        .then((result) => {
          console.log(result);
          if (result.data.status_gol == "Y") {
            this.is_gol_paid = true;
          } else {
            this.is_gol_paid = false;
          }
        })
        .catch((err) => {
          /* this.sudahIsiForm = false; */
        });
    },
    getPDF() {
      this.$http
        .get(
          "/get_status_pembayaran/" + this.$store.state.currentUser.username,
          {}
        )
        .then((result) => {
          let routeData = this.$router.resolve({
            name: "form-final",
            params: { nisn: result.data.nisn },
          });
          window.open(routeData.href, "_blank");
        });
    },
    startForm() {
      if (this.$store.state.currentUser) {
        this.$http
          .get(
            "/get_status_pembayaran/" + this.$store.state.currentUser.username,
            {}
          )
          .then((result) => {
            if (result.data.status == "Y") this.showForm = true;
            else this.$router.push("/pendaftaran/konfirmasi");

            // let routeData = this.$router.resolve({ name: "form-final", params: { nisn: result.data.nisn } });
            // window.open(routeData.href, "_blank");
          })
          .catch((err) => {
            this.$router.push("/pendaftaran/konfirmasi");
          });
      } else {
        this.$router.push("/buat-akun");
      }
    },
    addPrestasi() {
      this.listPrestasi.push(this.prestasi);
      this.prestasi = {};
      this.newPrestasi = false;
    },
    onSubmit() {
      this.newData.tgl_lahir = this.newData.tgl_lahir
        .split("/")
        .reverse()
        .join("-");
      this.newData.tgl_lahir_ayah = this.newData.tgl_lahir_ayah
        .split("/")
        .reverse()
        .join("-");
      this.newData.tgl_lahir_ibu = this.newData.tgl_lahir_ibu
        .split("/")
        .reverse()
        .join("-");
      if (this.newData.tgl_lahir_wali) {
        this.newData.tgl_lahir_wali = this.newData.tgl_lahir_wali
          .split("/")
          .reverse()
          .join("-");
      }

      var nama_file_ktp = "";
      var nama_file_kk = "";
      var nama_file_akte = "";
      var d = new Date();
      var dateString =
        "_" +
        d.getFullYear().toString() +
        (d.getMonth() + 1).toString() +
        d.getDate().toString() +
        "_";

      this.FileKTP = this.$refs.ktp.files[0];
      if (this.FileKTP) {
        nama_file_ktp =
          this.$store.state.currentUser.id + dateString + this.FileKTP.name;
        var formData = new FormData();
        formData.append("file", this.FileKTP);
        formData.append("newname", nama_file_ktp);
        formData.append("id", this.$store.state.currentUser.id);
        this.$http
          .post("/upload.php", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((result) => {});
      } else {
        nama_file_ktp = this.newData.file_ktp;
      }

      this.FileKK = this.$refs.kk.files[0];
      if (this.FileKK) {
        var d = new Date();
        nama_file_kk =
          this.$store.state.currentUser.id + dateString + this.FileKK.name;
        var formData = new FormData();
        formData.append("file", this.FileKK);
        formData.append("newname", nama_file_kk);
        formData.append("id", this.$store.state.currentUser.id);
        this.$http
          .post("/upload.php", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((result) => {});
      } else {
        nama_file_kk = this.newData.file_kk;
      }

      this.FileAkte = this.$refs.akte.files[0];
      if (this.FileAkte) {
        var d = new Date();
        nama_file_akte =
          this.$store.state.currentUser.id + dateString + this.FileAkte.name;
        var formData = new FormData();
        formData.append("file", this.FileAkte);
        formData.append("newname", nama_file_akte);
        formData.append("id", this.$store.state.currentUser.id);
        this.$http
          .post("/upload.php", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((result) => {});
      } else {
        nama_file_akte = this.newData.file_akte;
      }

      this.newData.file_ktp = nama_file_ktp;
      this.newData.file_kk = nama_file_kk;
      this.newData.file_akte = nama_file_akte;

      this.$http
        .post("/pendaftar_baru/" + this.newData.id, this.newData, {})
        .then((result) => {
          var length = this.listPrestasi.length;
          for (var i = 0; i < length; i++) {
            this.listPrestasi[i].id_pendaftar =
              this.$store.state.currentUser.id;
            this.listPrestasi[i].nama = this.newData.nama;
            this.listPrestasi[i].nisn = this.newData.nisn;
            this.$http
              .post("/prestasi_pendaftar", this.listPrestasi[i], {})
              .then((resp) => {});
          }

          // this.$http.post("/notif_pendaftaran/" + result.data + "/" + this.newData.username, {}).then((result) => {});

          this.$q.notify({
            icon: "done",
            color: "positive",
            message: "Pengisian formulir berhasil, Silahkan cek email anda",
          });

          this.cekPembayaran();
          this.getLatestFormData();
          this.step = 1;
          this.showForm = false;
          // this.newData = {
          //   nation: "WNI",
          //   nation_ayah: "WNI",
          //   nation_ibu: "WNI",
          //   nation_wali: "WNI",
          //   jenis_kelamin: "L",
          //   gol_darah: "O",
          // };
        });
    },

    chgFileKTP() {
      if (this.$refs.ktp.files[0])
        this.newData.file_ktp = this.$refs.ktp.files[0].name;
      else this.newData.file_ktp = "";
    },
    chgFileKK() {
      if (this.$refs.kk.files[0])
        this.newData.file_kk = this.$refs.kk.files[0].name;
      else this.newData.file_kk = "";
    },
    chgFileAkte() {
      if (this.$refs.akte.files[0])
        this.newData.file_akte = this.$refs.akte.files[0].name;
      else this.newData.file_akte = "";
    },
  },
  computed: {
    maxHeight() {
      return this.$q.screen.height;
    },
    progressLabel1() {
      return this.step + " of " + 5;
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #fff;
  // overflow: auto;
}

.pertanyaan {
  padding-bottom: 8px;
  @media all and(min-width: 500px) {
    display: flex;
    align-content: stretch;
  }
  label {
    color: slategray;
    width: 100%;
    @media all and(min-width: 500px) {
      width: 250px;
    }
  }
  .q-input,
  .q-textarea,
  .q-field,
  .q-btn-group,
  .upload-custom {
    width: 100%;
    @media all and(min-width: 500px) {
      width: 500px;
    }
  }
}
.form-step {
  overflow: auto;
  height: calc(100% - 15px);
}
.form-area {
  max-width: 800px;
  margin: 0 auto;
}
.bottom {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
input {
  background-color: #fff;
}
.q-field__native,
.q-field__input {
  background-color: #fff;
}

input {
  // width: 100%;
  border: 1px solid silver;
  border-radius: 3px;
  line-height: 28px;
  padding: 6px 0px;
  margin-bottom: 30px;
}
label {
  color: #606060;
}
.browse-area {
  width: 100%;
  @media all and(min-width: 500px) {
    width: 500px;
  }
  line-height: 28px;
  margin: 0px;
  border: 1px solid silver;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}
</style>
