import { render, staticRenderFns } from "./Pendaftaran.vue?vue&type=template&id=27180d52&scoped=true&"
import script from "./Pendaftaran.vue?vue&type=script&lang=js&"
export * from "./Pendaftaran.vue?vue&type=script&lang=js&"
import style0 from "./Pendaftaran.vue?vue&type=style&index=0&id=27180d52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27180d52",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QField from 'quasar/src/components/field/QField.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTimeline,QTimelineEntry,QDialog,QCard,QLinearProgress,QBadge,QInput,QBtnGroup,QRadio,QField,QIcon,QCardSection,QPageContainer});qInstall(component, 'directives', {ClosePopup});
